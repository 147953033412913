import React, { useState } from 'react';
import { auth } from '../apiClient'

const Login = ({ login, PageComponent }) => {
  const [password, setPassword] = useState('')
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [authError, setAuthError] = useState(false)

  function handlePasswordChange (e) {
    setPassword(e.target.value)
  }

  async function handleAuth () {
    auth({ login, password }).then(() => {
      setAuthError(false)
      setIsAuthorized(true)
    }).catch(err => {
      setAuthError(true)
    })
  }

  if (isAuthorized) {
    return <PageComponent />
  } else {
    return (
      <section>
        <div className="login-form">
          <label htmlFor="password">enter password</label>
          <input type="password" name="password"
                 value={password}
                 onChange={handlePasswordChange}
                 placeholder="Password"
                 className={authError ? 'error-input' : ''}
          />
          {authError && (<div className="error">incorrect password</div>)}
          <button onClick={handleAuth}>Enter</button>
        </div>
      </section>
    )
  }
}

export default Login
