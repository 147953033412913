import React, {useState, useEffect} from 'react';
import Result from '../components/Result'
import { getAppData } from '../apiClient'

const Home = () => {
  const [hotels, setHotels] = useState([])
  const [chosenOffice, setChosenOffice] = useState(0)
  const [fullName, setFullName] = useState('')
  const [position, SetPosition] = useState('')
  const [disabledMobile, setDisabledMobile] = useState(true)
  const [disabledTelephone, setDisabledTelephone] = useState(true)
  const [mobile, setMobile] = useState('')
  const [telephone, setTelephone] = useState('')
  const [instagramLink, setInstagramLink] = useState('')
  const [facebookLink, setFacebookLink] = useState('')
  const [puroLink, setPuroLink] = useState('')
  const [footerPL, setFooterPL] = useState('')
  const [footerEN, setFooterEN] = useState('')


  useEffect(() => {
    getAppData().then(appData => {
      setHotels(appData.hotels)
      setFooterPL(appData.footerPL)
      setFooterEN(appData.footerEN)
      setInstagramLink(appData.instagramLink)
      setFacebookLink(appData.facebookLink)
      setPuroLink(appData.puroLink)
    })
  }, []);

  const handleNameChange = (event) => {
    setFullName(event.target.value)
  }
  const handlePositionChange = (event) => {
    SetPosition(event.target.value)
  }

  const handleMobileChange = (event) => {
    setMobile(event.target.value)
  }

  const handleMobileCheck = () => {
    setDisabledMobile(!disabledMobile)
  }

  const handleTelephoneChange = (event) => {
    setTelephone(event.target.value)
  }

  const handleTelephoneCheck = () => {
    setDisabledTelephone(!disabledTelephone)
  }

  const handleOfficeChange = (event) => {
    setChosenOffice(event.target.value)
  }

  const hotelsComponent = hotels.map((hotel, index) => {
      return (
        <span className="single-option" key={index}>
          <input onChange={handleOfficeChange} type="radio" id={`office ${index}`} name="office" value={index}
                 defaultChecked={index === chosenOffice}/>
          <label htmlFor={`office ${index}`}>{hotel.label}</label>
        </span>
      )
    }
  )

  return (
    <section>
      <article className="generator">
        <header>
          <div>
            <img src="/img/puro.png" alt="puro-logo"/>
          </div>
          <div className="title">Signature<br/><span>creator</span></div>
        </header>
        <form>
          <span className="city label">City / branch</span>
          <span className="office-choice">
            {hotelsComponent}
          </span>


          <label htmlFor="fullName">first and last name</label>
          <input onChange={handleNameChange} type="text" name="fullName" id="fullName"
                 value={fullName} placeholder="Name"/>
          <label htmlFor="position">position</label>
          <input onChange={handlePositionChange} type="text" name="position" id="position"
                 value={position} placeholder="Position"/>


          <span className='checkbox-wrap' onClick={handleTelephoneCheck}>
            <label htmlFor="telephoneCheck">Using desk phone?</label>
            <input id="telephoneCheck" name="telephoneCheck" type="checkbox"
                   disabled={true}
                   checked={!disabledTelephone}/>
            <span className="checkmark"></span>
          </span>


          <label htmlFor="telephone">telephone</label>
          <input disabled={disabledTelephone} onChange={handleTelephoneChange}
                 type="tel" name="telephone" id="telephone"
                 value={telephone} placeholder="+48"/>


          <span onClick={handleMobileCheck} className='checkbox-wrap'>
            <label htmlFor="mobileCheck">Using mobile?</label>
            <input disabled={true} id="mobileCheck" name="mobileCheck"
                   type="checkbox" checked={!disabledMobile}/>
            <span className="checkmark"></span>
          </span>


          <label htmlFor="mobile">mobile</label>
          <input disabled={disabledMobile} onChange={handleMobileChange} type="tel"
                 name="mobile" id="mobile" value={mobile} placeholder="+48"/>
        </form>
      </article>
      <article className="result">
        <header>
          <span>Copy</span> the content from the box below<br/>
          <span>and paste</span> as a signature in your e-mail client.
        </header>
        <Result allProps={{
          office: hotels[chosenOffice] || {},
          fullName,
          position,
          mobile,
          telephone,
          disabledMobile,
          disabledTelephone,
          instagramLink,
          facebookLink,
          puroLink,
          footerPL,
          footerEN
        }}/>
      </article>
    </section>
  )
}

export default Home
