import React, { useRef } from 'react';

const Result = ({ allProps }) => {
  const footerRef = useRef(null)

  function copyFooter () {
    const element = footerRef.current
    console.log('EL', element)
    if (element) {
      navigator.clipboard.write([new ClipboardItem({
        'text/plain': new Blob([element.innerText], {type: 'text/plain'}),
        'text/html': new Blob([element.innerHTML], {type: 'text/html'})
      })])
    }
  }

  const { fullName, position, mobile, disabledMobile, telephone, disabledTelephone, office } = allProps
  return (
    <div className="footer-wrapper">
      <div className="separator top"></div>
      <div ref={footerRef} className="footer-content">
        <table border={0} cellPadding={0} cellSpacing={0} align={'left'}>
          <tr>
            <td style={{padding: 0}}>
              <p style={{
                margin: 0,
                fontSize: '15px',
                textTransform: 'uppercase',
                lineHeight: '17px',
                fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
                color: 'black',
                fontWeight: '700'
              }}>{fullName === '' ? 'Name' : fullName}</p>
              <p style={{
                margin: 0,
                fontSize: '15px',
                lineHeight: '17px',
                fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
                color: 'black',
                fontWeight: '400'
              }}>{position === '' ? 'Position' : position}</p>

              {(!disabledMobile || !disabledTelephone) &&
                <p style={{fontSize: '11px', lineHeight: '18px', margin: 0}}>&nbsp;</p>}

              {!disabledMobile && <p style={{
                fontSize: '11px',
                lineHeight: '18px',
                fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
                color: 'black',
                fontWeight: '400',
                margin: 0
              }}>Mob.: {mobile === '' ? '+48' : mobile}</p>}
              {!disabledTelephone && <p style={{

                fontSize: '11px',
                lineHeight: '18px',
                fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
                color: 'black',
                fontWeight: '400',
                margin: 0
              }}>Tel.: {telephone === '' ? '+48' : telephone}</p>}

              <p style={{fontSize: '11px', lineHeight: '18px', margin: 0}}>&nbsp;</p>
              <p style={{fontSize: '11px', lineHeight: '18px', margin: 0}}>&nbsp;</p>
              <a style={{width: "77px", height: "auto"}} href={allProps.puroLink} target="_blank">
                <img style={{width: "77px", height: "auto"}}
                     src={process.env.REACT_APP_PUBLIC_URL + '/img/puro_footer.png'}
                     width="77" height="auto" alt="puro"/>
              </a>
              <p style={{fontSize: '13px', lineHeight: '22px', margin: 0}}>&nbsp;</p>
              <p style={{
                fontSize: '11px',
                lineHeight: '18px',
                fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
                color: 'black',
                fontWeight: '400',
                margin: 0
              }}>www.purohotels.com</p>
              <p style={{
                fontSize: '11px',
                lineHeight: '18px',
                fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
                color: 'black',
                fontWeight: '400',
                margin: 0
              }}>{office.name}</p>
              <p style={{
                fontSize: '11px',
                lineHeight: '18px',
                fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
                color: 'black',
                fontWeight: '400',
                margin: 0
              }}>{office.street}</p>
              <p style={{
                fontSize: '11px',
                lineHeight: '18px',
                fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
                color: 'black',
                fontWeight: '400',
                margin: 0
              }}>{office.city}</p>
              <p style={{fontSize: '11px', lineHeight: '14px', margin: 0}}>&nbsp;</p>
              <p style={{fontSize: '11px', lineHeight: '13px', margin: 0}}>&nbsp;</p>
              <a style={{width: "18px", height: "18px", display: 'inline-block'}} href={allProps.facebookLink}
                 target="_blank">
                <img style={{width: "18px", height: "18px"}}
                     src={process.env.REACT_APP_PUBLIC_URL + '/img/facebook.png'}
                     width="18" height="18" alt="facebook"/>
              </a>
              <a style={{display: 'inline-block'}}>&nbsp;&nbsp;</a>
              <a style={{width: "18px", height: "18px", display: 'inline-block'}} href={allProps.instagramLink}
                 target="_blank">
                <img style={{width: "18px", height: "18px"}}
                     src={process.env.REACT_APP_PUBLIC_URL + '/img/instagram.png'} width="18"
                     height="18" alt="instagram"/>
              </a>
              <p style={{fontSize: '11px', lineHeight: '18px', margin: 0}}>&nbsp;</p>
              <p style={{
                color: '#A2A4A1',
                fontSize: '7px',
                lineHeight: '11px',
                fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
                fontWeight: '400',
                margin: 0
              }}>{office.disclaimer}</p>
              <p style={{fontSize: '7px', lineHeight: '11px', margin: 0}}>&nbsp;</p>
              <p style={{
                color: '#A2A4A1',
                fontSize: '7px',
                lineHeight: '11px',
                fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
                fontWeight: '400',
                margin: 0
              }}>{allProps.footerPL}</p>
              <p style={{fontSize: '7px', lineHeight: '11px', margin: 0}}>&nbsp;</p>
              <p style={{
                color: '#A2A4A1',
                fontSize: '7px',
                lineHeight: '11px',
                fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
                fontWeight: '400',
                margin: 0
              }}>{allProps.footerEN}</p>
              <p style={{fontSize: '7px', lineHeight: '11px', margin: 0}}>&nbsp;</p>
              <p style={{
                color: '#A2A4A1',
                fontSize: '7px',
                lineHeight: '11px',
                fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
                fontWeight: '400',
                margin: 0
              }}>
                Zapoznaj się z naszą <a style={{width: "18px", height: "18px", color: '#6f6f67'}}
                                        href={"https://purohotel.pl/pl/corporate/polityka-prywatnosci/"}
                                        target="_blank"
              >
                polityką prywatności.
              </a> / <a style={{width: "18px", height: "18px", color: '#6f6f67'}}
                        href={"https://purohotel.pl/en/corporate/terms/"} target="_blank"
              >
                Read our privacy policy.
              </a>
              </p>
            </td>
          </tr>
        </table>
      </div>
      {(!allProps.disableClickToCopy &&
        <button className={"copy-button"} onClick={copyFooter}>
          <div style={{margin: "auto", display: "flex"}}>
            <svg className={"copy-icon"} width="14" height="17" viewBox="0 0 14 17" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path className={"copy-icon-path"}
                    d="M10.7501 3.89091L7.57137 0.500244H2.62512C2.12784 0.500244 1.65093 0.710958 1.2993 1.08603C0.947666 1.4611 0.750122 1.96981 0.750122 2.50024V13.8336H10.7501V3.89091ZM2.00012 12.5002V2.50024C2.00012 2.32343 2.06597 2.15386 2.18318 2.02884C2.30039 1.90382 2.45936 1.83358 2.62512 1.83358H7.00012V4.50024H9.50012V12.5002H2.00012ZM13.2501 6.50024V16.5002H3.87512V15.1669H12.0001V5.16691L13.2501 6.50024Z"
              />
            </svg>
            <span>CLICK TO COPY</span>
          </div>
        </button>
      )}
    </div>
  )
}

export default Result

