import Home from './pages/Home'
import Admin from './pages/Admin'
import Login from './components/Login'
import './App.css'
import { Route, Routes, BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Login login="puro" PageComponent={Home} />}/>
        <Route path='/admin' element={<Login login="admin" PageComponent={Admin} />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
