import axios from 'axios'

const TOKEN_KEY = 'authToken'
const apiClient = axios.create({ baseURL: `${process.env.REACT_APP_PUBLIC_URL}/api` || 'http://localhost/api' })

apiClient.interceptors.request.use(config => {
  const token = sessionStorage.getItem(TOKEN_KEY)
  if (token) {
    config.headers.authToken = token
  }

  return config
})
export const auth = async payload => {
  return apiClient.post('/auth', payload).then(async res => {
    sessionStorage.setItem(TOKEN_KEY, res.data.token)
    return true
  })
}

export const getAppData = async () => {
  return apiClient.get('/appData').then(res => {
    return res.data
  })
}

export const saveAppData = async (body) => {
  return apiClient.put('/appData', body).then(res => {
    return res.data
  })
}

export const getDefaultAppData = async () => {
  return apiClient.get('/appData/default').then(res => {
    return res.data
  })
}
