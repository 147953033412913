import React, {useState, useEffect} from 'react';
import Result from '../components/Result'
import {getAppData, getDefaultAppData, saveAppData} from '../apiClient'

const Admin = () => {
  const [hotels, setHotels] = useState([])
  const [chosenOffice, setChosenOffice] = useState(0)
  const [instagramLink, setInstagramLink] = useState('')
  const [facebookLink, setFacebookLink] = useState('')
  const [puroLink, setPuroLink] = useState('')
  const [footerPL, setFooterPL] = useState('')
  const [footerEN, setFooterEN] = useState('')

  const _assignData = (appData) => {
    setHotels(appData.hotels)
    setFooterPL(appData.footerPL)
    setFooterEN(appData.footerEN)
    setInstagramLink(appData.instagramLink)
    setFacebookLink(appData.facebookLink)
    setPuroLink(appData.puroLink)
  }
  const loadData = async () => {
    getAppData().then(appData => {
      _assignData(appData)
    })
  }

  useEffect(() => {
    loadData()
  }, []);


  const handleOfficeChange = (event) => {
    setChosenOffice(event.target.value)
  }

  const removeOffice = (e, index) => {
    e.preventDefault()
    hotels.splice(index, 1)
    setHotels([...hotels])
    if (index === chosenOffice) {
      setChosenOffice(0)
    }
  }

  const addNewOffice = e => {
    e.preventDefault()
    hotels.push({
      label: 'New office label',
      name: 'New office name',
      city: 'New office city',
      street: 'New office street',
      disclaimer: 'This is new office disclaimer'
    })
    setHotels([...hotels])
  }

  const handleHotelPropertyChange = (event, property) => {
    hotels[chosenOffice][property] = event.target.value
    setHotels([...hotels])
  }

  const handleInstagramLinkChange = (event) => {
    setInstagramLink(event.target.value)
  }

  const handleFacebookLinkChange = (event) => {
    setFacebookLink(event.target.value)
  }

  const handlePuroLinkChange = (event) => {
    setPuroLink(event.target.value)
  }

  const handleFooterPLChange = (event) => {
    setFooterPL(event.target.value)
  }

  const handleFooterENChange = (event) => {
    setFooterEN(event.target.value)
  }

  const saveSettings = async (e) => {
    e.preventDefault()
    await saveAppData({
      hotels,
      instagramLink,
      facebookLink,
      puroLink,
      footerPL,
      footerEN
    })
    await loadData()
  }

  const resetDefault = async (e) => {
    e.preventDefault()
    const data = await getDefaultAppData()
    _assignData(data)
  }


  const hotelsComponent = hotels.map((hotel, index) => {
      return (
        <span className="single-option" key={index}>
          <button onClick={e => removeOffice(e, index)}
                  style={{ fontSize: '30px', fontWeight: 'bold', color: 'black', marginRight: '5px', top: '4px', position: 'relative' }}>-</button>
          <input onChange={handleOfficeChange} type="radio" id={`office ${index}`} name="office" value={index}
                 defaultChecked={index === chosenOffice}/>
          <label htmlFor={`office ${index}`}>{hotel.label}</label>
        </span>
      )
    }
  )

  return (
    <section>
      <article className="generator">
        <header style={{marginBottom: '30px'}}>
          <div>
          <img src="/img/puro.png" alt="puro-logo"/>
          </div>
          <div className="title">Signature<br/><span>creator</span></div>
        </header>
        <form>
          <div style={{display: "inline-flex"}}>
            <span className="city label">City / branch</span>
            <button onClick={e => addNewOffice(e)}
                    style={{ color: 'black', fontSize: '20px', marginLeft: '10px', position: "relative", bottom: '10px' }}>+</button>
          </div>

          <span className="office-choice" style={{marginBottom: '10px'}}>
            {hotelsComponent}
          </span>


          <label htmlFor="label">label</label>
          <input onChange={e => handleHotelPropertyChange(e, 'label')} type="text" name="label" id="label"
                 style={{marginBottom: '10px'}}
                 value={hotels[chosenOffice]?.label || ''} placeholder="Label"/>

          <label htmlFor="position">name</label>
          <input onChange={e => handleHotelPropertyChange(e, 'name')} type="text" name="name" id="name"
                 style={{marginBottom: '10px'}}
                 value={hotels[chosenOffice]?.name || ''} placeholder="Name"/>

          <label htmlFor="street">street</label>
          <input onChange={e => handleHotelPropertyChange(e, 'street')} type="text" name="street" id="street"
                 style={{marginBottom: '10px'}}
                 value={hotels[chosenOffice]?.street || ''} placeholder="Street"/>

          <label htmlFor="city">city</label>
          <input onChange={e => handleHotelPropertyChange(e, 'city')} type="text" name="city" id="city"
                 style={{marginBottom: '10px'}}
                 value={hotels[chosenOffice]?.city || ''} placeholder="City"/>

          <label htmlFor="disclaimer">disclaimer</label>
          <textarea onChange={e => handleHotelPropertyChange(e, 'disclaimer')} type="text" name="disclaimer"
                    id="disclaimer"
                    value={hotels[chosenOffice]?.disclaimer || ''} placeholder="Disclaimer"
                    className={'admin-textarea'}
                    style={{marginBottom: '30px'}}
                    rows={4}
          />

          <label htmlFor="instagramLink">instagram link</label>
          <input onChange={handleInstagramLinkChange} type="text" name="instagramLink" id="instagramLink"
                 style={{marginBottom: '10px'}}
                 value={instagramLink} placeholder="Instagram Link"/>

          <label htmlFor="facebookLink">facebook link</label>
          <input onChange={handleFacebookLinkChange} type="text" name="facebookLink" id="facebookLink"
                 style={{marginBottom: '10px'}}
                 value={facebookLink} placeholder="Facebook Link"/>

          <label htmlFor="puroLink">puro link</label>
          <input onChange={handlePuroLinkChange} type="text" name="puroLink" id="puroLink"
                 style={{marginBottom: '30px'}}
                 value={puroLink} placeholder="Puro Link"/>

          <label htmlFor="disclaimer">footer pl</label>
          <textarea onChange={handleFooterPLChange} type="text" name="footerPL"
                    id="footerPL"
                    value={footerPL} placeholder="Footer PL"
                    className={'admin-textarea'}
                    rows={4}
          />

          <label htmlFor="puroLink">footer en</label>
          <textarea onChange={handleFooterENChange} type="text" name="footerEN"
                    id="footerEN"
                    value={footerEN} placeholder="Footer EN"
                    className={'admin-textarea'}
                    style={{marginBottom: '30px'}}
                    rows={4}
          />

          <div style={{display: "flex", width: '80%'}}>
            <button style={{marginBottom: '30px'}} className={"copy-button button-admin"} onClick={saveSettings}>
              <div style={{margin: "auto", display: "flex"}}>
                <span>SAVE DATA</span>
              </div>
            </button>

            <button style={{marginBottom: '30px'}} className={"copy-button button-admin"} onClick={resetDefault}>
              <div style={{margin: "auto", display: "flex"}}>
                <span>RESET TO DEFAULT</span>
              </div>
            </button>
          </div>

        </form>
      </article>
      <article className="result">
        <header>
          <span>Copy</span> the content from the box below<br/>
          <span>and paste</span> as a signature in your e-mail client.
        </header>
        <Result allProps={{
          office: hotels[chosenOffice] || {},
          fullName: '',
          position: '',
          mobile: '',
          telephone: '',
          disabledMobile: '',
          disabledTelephone: '',
          instagramLink,
          facebookLink,
          puroLink,
          footerPL,
          footerEN,
          disableClickToCopy: true
        }}/>
      </article>
    </section>
  )
}

export default Admin
